<!-- =========================================================================================
    File Name: Register.vue
    Description: Register Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-login"
  >
    <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col w-full d-theme-dark-bg">
              <div class="p-8 google-ath-tabs-container">
                <div class="vx-card__title mb-4">
                  <div class="flex">
                    <router-link :to="{ name: 'login' }">
                      <feather-icon
                        icon="ArrowLeftIcon"
                        class="pr-3"
                      ></feather-icon>
                    </router-link>

                    <h4 class="mb-4">Google Authenticator</h4>
                  </div>
                </div>

                <div class="vx-row">
                  <div class="vx-col w-full">
                    <div class="content">
                      <p v-for="(item, index) in help" :key="index">
                        {{ index + 1 }}. {{ item.content }}
                      </p>
                    </div>

                    <div class="tip">
                      <vs-chip color="danger">
                        Note
                      </vs-chip>
                      <p class="text-danger">
                        dont lose your QR code atall , if u lose it , you should
                        paid 10$ for reset by google
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      help: [
        {
          content: "first download google Authenticator app on your smart phone"
        },
        {
          content: "click on dont have"
        },
        {
          content:
            "scan QR code that will be shown on ( ecotradecenter-ancectryglobal ) by your google Auhtenticator app on your phone"
        },
        {
          content:
            "put the timer code from app to the ( ecotradecenter - ancectryflobal ) website"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
@import "./google-help.scss";
</style>
